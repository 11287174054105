<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status']"
            ref="list"
            :submit_preprocessing="submit_preprocessing"
        >
			<template slot="right_btn">
                <a :href="'/manage/crm.export/trip' | export_form" v-if="this.$store.state.page_auth.export" target="_blank">
					<a-button  class="a_btn">导出</a-button>
				</a>
            </template>

            <template slot="status" slot-scope="data">
                <span v-if="data.text=='1'">已处理</span>
                <span v-else-if="data.text=='0'">待处理</span>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="get_details(data.record,data.index)">查看详情</a>
            </template>
        </TableList>
		<Details ref="details" :visible.sync="drawer_visible"></Details>
    </div>
</template>

<script>

import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import Details from '@/views/business/index/details'

import { tripList, tripInfo } from "@/api/attendance";

const columns = [
    {
        title: "姓名",
        dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "department",
    },
    {
        title: "出发地",
        dataIndex: "start_place_name",
    },
    {
        title: "到达地",
        dataIndex: "end_place_name",
    },
    {
        title: "出发时间",
        dataIndex: "start_time",
    },
    {
        title: "结束时间",
        dataIndex: "end_time",
    },
    {
        title: "时长",
        dataIndex: "duration",
    },
    {
        title: "出差事由",
        dataIndex: "desc",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

const columns_details = [
    {
        title: '商品',
        dataIndex: 'goods_title'
    },
    {
        title: '价格',
        dataIndex: 'pay_price'
    },
    {
        title: '数量',
        dataIndex: 'num',
        scopedSlots: {
            customRender: "num"
        }
    },
    {
        title: '小计',
        dataIndex: 'sub_price'
    }
]

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,
		Details,
		
    },
    data() {
        return {
            get_table_list: tripList,
            submit_preprocessing: {
                array_to_string: ['department']
            },
            details: {
                goods: [],
            },
            columns,
            columns_details,
            drawer_visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "text",
                        name: "keyword",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
					},
					{
                        type: "select",
                        name: "user_status",
                        title: "在职状态",
                        mode: "default",
                        options: {},
                        list: this.$config.user_status_list
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
        });
    },
    methods: {
        get_details(item, index) {
            this.$refs.details.get_details(item);
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>