<template>
    <RightDrawer title="出差详情" :visible.sync="visible">
        <template slot="content">
            <UserHeader
                :head_img="details.headimg"
                :name="details.username"
                :info="details.create_time"
            ></UserHeader>
            <a-divider />
            <a-descriptions>
                <a-descriptions-item :span="3" label="部门">{{details.department}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="出发地">{{details.start_place_name}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="到达地">{{details.end_place_name}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="出发时间">{{details.start_time}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="结束时间">{{details.end_time}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="时长">{{details.duration}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="出差事由">{{details.desc}}</a-descriptions-item>
            </a-descriptions>
            <a-divider />
            <a-timeline>
                <a-timeline-item v-for="item in details.route" :key="item.create_time">
                    <div class="time_business_list">
                        <div class="name"></div>
                        <div class="time">{{item.create_time}}</div>
                        <div class="address">{{item.address}}</div>
                        <LookImages :list="item.image"></LookImages>
                    </div>
                </a-timeline-item>
            </a-timeline>
        </template>
    </RightDrawer>
</template>

<script>
import RightDrawer from "@/components/RightDrawer";
import { tripInfo } from "@/api/attendance";
import UserHeader from "@/components/UserHeader";
import LookImages from "@/components/LookImages";

export default {
	props:{

	},
	components:{
		RightDrawer,
		UserHeader,
		LookImages
	},
	data(){
		return {
			visible:false,
			details:{}
		}
	},
	created(){
		
	},
	methods:{
		get_details(item) {
            tripInfo({
                data: {
                    id: item.id
                }
            }).then(res => {
                this.details = res.data.list;
				this.visible = true;
            })
        },
	}
}
</script>