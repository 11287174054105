<template>
	<div class="approval_header">
		<a-avatar :size="60" :src="head_img"  />
		<div class="cont">
			<div class="title">{{name}}</div>
			<div class="time">{{info}}</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'UserHeader',
	props:{
		head_img:{
			type:String,
			default:''
		},
		name:{
			type:String,
			default:''
		},
		info:{
			type:String,
			default:''
		}
	}
}
</script>

<style lang="less" scoped>
.approval_header{
	display: flex;
	.cont{
		padding-left: 10px;
		.title{
			margin-top: 8px;
		}
		.time{
			margin-top: 5px;
		}
	}
}
</style>

