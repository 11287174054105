<template>
    <a-drawer
        :title="title"
        :placement="placement"
        :width="width"
        :closable="false"
        @close="drawer_visible_close"
        :visible="visible"
		:destroyOnClose="true"
    >
		<slot name="content"></slot>
		<div class="h53"></div>
        <div class="drawer_btns">
            <slot name="btns">
				<a-button @click="drawer_visible_close" type="primary">关闭</a-button>
			</slot>
        </div>
    </a-drawer>
</template>

<script>
export default {
	props:{
		width:{
			type:String,
			default:'30%'
		},
		placement:{
			type:String,
			default:'right',
		},
		title:{
			type:String,
			default:''
		},
		visible:{
			type:Boolean,
			default:false,
		}
	},
	data(){
		return {

		}
	},
	methods:{
		drawer_visible_close(){
			this.$emit('update:visible',false);
		}
	}
}
</script>

